$primary-color: #103559;
$primary-old-color: #10365a;
$primary-soft-color: #295889;
$secondary-color: #ff4d4d;
$secondary-light-color: #f7f0eb;
$light-color: #00000029;
$beige-color: #ccd6e0;
$success-color: #48d2a0;
$white-color: #fff;
$black-color: #000;
$close-navigation-toggle-color: #ebccbb;
$beige-strong-color: #f4e3d7;
$disabled-cta-color: #f4e8df;
$touch-handle-color: #f4d6c6;
$light-blue: #ecf1f7;
$error-color: #ff1111;
$link-color: #0000e4;

$amber-color: #ffbf00;
