// keep this at the top of the stylesheet
@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,600|Montserrat:300,400,900');
@import 'styles/colors';

html,
body {
  margin: 0;
  padding: 0;
}
body {
  font-family: 'Sailec', 'Roboto', sans-serif;
}

.container {
  margin: auto;
  text-align: center;
  max-width: 600px;
  color: white;

  &__image {
    width: 95px;
    height: auto;
  }
}

.left {
  text-align: left;
}

a {
  color: $primary-color;
}
